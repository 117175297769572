[data-component='Button'] {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: 'Roboto Mono', monospace;
  font-size: 12px;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  border: none;
  padding: 8px 24px;
  min-height: 42px;
  transition:
    transform 0.1s ease-in-out,
    background-color 0.1s ease-in-out;
  outline: none;
  width: 100%;
  justify-content: center;

  &.button-style-action {
    background-color: rgba(237, 237, 237, 0.1);

    &:hover:not([disabled]) {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &.button-style-alert {
    background-color: #0c830f;

    &:hover:not([disabled]) {
      background-color: #0c830f;
    }
  }

  &.button-style-flush {
    background-color: rgba(255, 255, 255, 0);
  }

  &:not([disabled]) {
    cursor: pointer;
  }

  &:hover:not([disabled]) {
    background-color: rgba(255, 255, 255, 0.8);
    transition:
      background-color 0.6s ease,
      transform 0.6s ease;
  }

  &:active:not([disabled]) {
    transform: translateY(1px);
  }

  .icon {
    display: flex;

    &.icon-start {
      margin-left: -8px;
    }

    &.icon-end {
      margin-right: -8px;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &.icon-red .icon {
    color: #cc0000;
  }

  &.icon-green .icon {
    color: #80cc29;
  }

  &.icon-grey .icon {
    color: #909090;
  }

  &.icon-fill {
    svg {
      fill: currentColor;
    }
  }
}
