.table {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: 100%;
  background-color: #1e1e1e;
  color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10000;
  overflow: auto;
  border-radius: 8px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.close {
  cursor: pointer;
  font-size: 20px;
  float: right;
  margin-bottom: 10px;
}

.th {
  border: 1px solid gray;
  padding: 8px;
}

.tdKey {
  border: 1px solid gray;
  padding: 8px;
  background-color: #2e2e2e;
}

.tdValue {
  border: 1px solid gray;
  padding: 8px;
  word-break: break-all;
  white-space: pre-wrap;
  background-color: #2e2e2e;
}

.closeDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
