[data-component='Toggle'] {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  height: 40px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
    transition:
      background-color 0.6s ease,
      transform 0.6s ease;
  }

  div.label {
    position: relative;
    transition: color 0.1s ease-in-out;
    padding: 0px 16px;
    z-index: 2;
    width: 50%;
    text-align: center;
    user-select: none;
  }

  div.label.right {
    margin-left: -8px;
  }

  .toggle-background {
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0px;
    left: 0px;
    width: auto;
    bottom: 0px;
    z-index: 1;
    border-radius: 10px;
    transition:
      left 0.1s ease-in-out,
      width 0.1s ease-in-out;
  }

  &[data-enabled='true'] {
    div.label.right {
      //color: #f1f1f1;
    }
  }

  &[data-enabled='false'] {
    div.label.left {
      //color: #f1f1f1;
    }
  }
}
