* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  position: relative;
  width: 100%;
  height: 100%;
  font-family: 'Assistant', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('./static/bg_light.jpg');
  color: #000000;
  overflow-y: auto;

  input {
    color: #000000;
  }

  Button {
    color: #000000;
  }

  audio {
    margin-top: 10px;
    max-height: 30px;
    width: 100%;
    max-width: 100%;
    border: none;
    background-color: transparent;
  }

  button {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    /* iOS Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Standard syntax */
  }

  .container_bg {
    background-color: rgba(237, 237, 237, 0.7);
  }

  a {
    color: #000000;
  }
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
}

body.night-mode {
  background-image: url('./static/bg_dark.jpg');
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 20% 20%;
  color: #dddddd;

  input {
    color: #dddddd;
  }

  Button {
    color: #dddddd;

    &:hover:not([disabled]) {
      background-color: rgba(133, 132, 132, 0.2);
    }
  }

  .container_bg {
    background-color: rgba(237, 237, 237, 0.2);
  }

  .camera {
    background-image: url('static/camera-night.svg') !important;
  }

  .toggle-background {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
